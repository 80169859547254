import type { PartialRecord } from '../helpers/types';
import { LOCALES } from './locales';
import type { TLocale, TLocaleDetails } from './types';

export const AVAILABLE_LANGUAGES: PartialRecord<TLocale, TLocaleDetails> = {
    [LOCALES.EN_GB]: {
        code: 'en-GB',
        name: 'English (United Kingdom)'
    },
    [LOCALES.SV]: {
        code: 'sv-SE',
        name: 'Svenska'
    },
    [LOCALES.ES]: {
        code: 'es-ES',
        name: 'Spanish'
    }
} as const;
