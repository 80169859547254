import { AVAILABLE_MARKETS } from './constants';
import type { TMarketSlug } from './types';

export const getAvailableMarketSlugs = () => {
    return Object.keys(AVAILABLE_MARKETS) as TMarketSlug[];
};

export const getMarketDetailsBySlug = (slug: TMarketSlug) => {
    return AVAILABLE_MARKETS[slug];
};
