export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0,8,2,3];

export const dictionary = {
		"/[[market=isMarket]]/iddqd": [16,[8]],
		"/[[market=isMarket]]/iddqd/error-pages": [17,[8]],
		"/[[market=isMarket]]/iddqd/error-pages/maintainance": [19],
		"/[[market=isMarket]]/iddqd/error-pages/[code]": [~18],
		"/[[market=isMarket]]/iddqd/tools": [~20,[8]],
		"/(content)/(no-hub)/[[market=isValidMarket]]/more": [~10,[2]],
		"/(noshell)/[[market=isMarket]]/(play-games)/practise/[gameSlug]": [14,[7]],
		"/(noshell)/[[market=isMarket]]/register": [~15,[7]],
		"/(content)/(hub)/[...pathname=isValidHubContentUrl]": [~9,[2,3]],
		"/(content)/(overlay)/[...pathname=isValidContentOverlayUrl]": [11,[2,4,5]],
		"/(content)/(sports-hub)/[...pathname=isValidSportsHubContentUrl]": [~12,[2,6]],
		"/(noshell)/[...pathname=isValidNoShellUrl]": [~13,[7]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';